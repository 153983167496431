// Configs
export const GET_SPIN_CONFIG = "/spin-clients"
export const GET_LANGUAGE_CONFIG = "/spin-clients/language"
export const VERIFY_SPIN_CODE = "/spin-clients/verify"
export const VERIFY_SPIN_PASSWORD = "/spin-clients/access"

// Dial Clients
export const GET_DIAL_CONFIG = "/dial-clients";
export const GET_LANGUAGE_DIAL_CONFIG = "/dial-clients/language";
export const GET_DIAL_CLIENT_PLAYERS = "/dial-clients/players";
export const GET_ORDER_DIAL = "/dial-clients/order-dial";
export const SPIN_DIAL = "/dial-clients/player-sucess";
export const SAVE_PLAYER = "/dial-players/save-success"

// Players
export const SPIN_WITH_INFOR = "/players/info";
export const SPIN_WITH_CODE = "players/spin-code";
export const SPIN_WITH_CODE_NO_INFO = "players/spin-code-no-info";
export const SPIN_WITH_NO_INFO = "/players/no-info";
export const VALIDATION_INFOR = "/players/validation-info";
export const VALIDATION_SPIN_CODE = "/players/validation-spin-code";
export const VALIDATION_SPIN_CODE_NO_INFO = "/players/validation-spin-code-no-info";
export const VALIDATION_NO_INFO = "/players/validation-no-info";
export const GET_PLAYERS = "/players/client"
