import * as url from "../configs/url_base";
import { APIBase } from "../configs/axiosBase";

const apiBase = new APIBase();

export const getSpinConfigs = (spinId, params) =>
  apiBase.get(`${url.GET_SPIN_CONFIG}/${spinId}`, {
    params,
  });
export const getLanguageConfig = (spinId) =>
  apiBase.get(`${url.GET_LANGUAGE_CONFIG}/${spinId}`);
export const verifySpinCode = (spinId) =>
  apiBase.get(`${url.VERIFY_SPIN_CODE}/${spinId}`);
export const verifySpinPassword = (data) =>
  apiBase.post(url.VERIFY_SPIN_PASSWORD, data);
export const spinWithInfor = (data) => apiBase.post(url.SPIN_WITH_INFOR, data);
export const spinWithCode = (data) => apiBase.post(url.SPIN_WITH_CODE, data);
export const spinWithCodeNoInfo = (data) =>
  apiBase.post(url.SPIN_WITH_CODE_NO_INFO, data);
export const spinWithNoInfo = (data) =>
  apiBase.post(url.SPIN_WITH_NO_INFO, data);
export const validateInfor = (body) => apiBase.post(url.VALIDATION_INFOR, body);
export const validateSpinCode = (body) =>
  apiBase.post(url.VALIDATION_SPIN_CODE, body);
export const validateSpinCodeNoInfo = (body) =>
  apiBase.post(url.VALIDATION_SPIN_CODE_NO_INFO, body);
export const validateNoInfo = (body) =>
  apiBase.post(url.VALIDATION_NO_INFO, body);
export const getPlayers = (spinId, params) =>
  apiBase.get(`${url.GET_PLAYERS}/${spinId}`, {
    params,
  });

// Dial
export const getDialConfigs = (spinId) =>
  apiBase.get(`${url.GET_DIAL_CONFIG}/${spinId}`);
export const getDialLanguageConfig = (spinId) =>
  apiBase.get(`${url.GET_LANGUAGE_DIAL_CONFIG}/${spinId}`);
export const getDialClientPlayers = (params) =>
  apiBase.get(`${url.GET_DIAL_CLIENT_PLAYERS}`,{params});
export const getOrderDial = (params) =>
  apiBase.get(url.GET_ORDER_DIAL, {
    params,
  });
export const spinDial = (params) =>
  apiBase.get(url.SPIN_DIAL, {
    params,
  });
export const savePlayers = (prizeId, body) =>
  apiBase.patch(`${url.SAVE_PLAYER}/${prizeId}`, body);
