import React, {useState, useEffect} from "react";
import {message} from "antd";
import LuckyWheel from "../components/LuckySpin/LuckyWheel";
import LuckySlot from "../components/LuckySlot/LuckySlot";
import loadingIcon from "../assets/loading.svg";
import InformationUserForm from "../components/Form/InformationUserForm";
import InformationSpin from "../components/InformationSpin";
import AwardModal from "../components/Modal/AwardModal";
import InputPassForm from "../components/Form/InputPassForm";
import ListPlayers from "../components/ListPlayers";
import {useSpinConfigsStore, useWheelPrizesStore} from "../stores/store";
import {
  getSpinConfigs,
  getDialConfigs,
  getLanguageConfig,
  getDialLanguageConfig, getDialClientPlayers,
} from "../services/http";
import {setCookie, getCookie} from "../utils/cookies";
import {COOKIES, LANGUAGES, EVENT_TYPES} from "../utils/constants";
import {useLocation, useSearchParams} from "react-router-dom";
import InitSignalR from "../signalr/init";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";
import NotFound from "./NotFound";

const Home = (props) => {
  const [searchParams] = useSearchParams();
  const {i18n} = useTranslation();
  const isMobile = useMediaQuery({maxWidth: 768});

  const location = useLocation();
  const pathname = location.pathname;
  const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);
  const eventType = searchParams.get("type");

  const {configs, setSpinConfigs} = useSpinConfigsStore((state) => state);
  const [isNotFound, setIsNotFound] = useState(false);
  const setWheelPrizesState = useWheelPrizesStore(
    (state) => state.setSpinPrizes
  );
  const [isOpenInputPassForm, setIsOpenInputPassForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isCustomGui, setIsCustomGui] = useState(false);
  const [clientPlayerDialData, setClientPlayerDialData] = useState([]);
  useEffect(() => {
    if (eventType == EVENT_TYPES.DIAL) {
      fetchConfigDialData();
      fetchClientsPlayerDialData()
    } else {
      fetchConfigSpinData();
    }
  }, []);

  const fetchConfigSpinData = async () => {
    setLoading(true);

    try {
      const spinId = lastSegment;
      const password = getCookie(COOKIES.PASSWORD(spinId));
      const params = {
        password: password,
      };

      const responseLanguage = await getLanguageConfig(spinId);
      const response = await getSpinConfigs(spinId, params);

      i18n.changeLanguage(LANGUAGES[responseLanguage?.data?.languageType]);

      if (response.code === 200) {
        setSpinConfigs(response?.data);
        const title = response?.data?.eventName || "Vòng quay may mắn";
        document.title = title;

        setCookie(COOKIES.GENERATE_KEY, response?.data?.generateKey, 1);
        setWheelPrizesState(
          response?.data?.prizes,
          response?.data?.luckySpinGui
        );
        setIsCustomGui(response?.data?.isCustom);
      } else if (response.code === 423) {
        setIsOpenInputPassForm(true);
      } else if (response.code === 404) {
        setIsNotFound(true);
      } else {
        message.error(response.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchConfigDialData = async () => {
    setLoading(true);

    try {
      const spinId = lastSegment;

      const responseLanguage = await getDialLanguageConfig(spinId);
      const response = await getDialConfigs(spinId);

      i18n.changeLanguage(LANGUAGES[responseLanguage?.data?.languageType]);

      if (response.code === 200) {
        setSpinConfigs(response?.data);
        const title = response?.data?.eventName || "Vòng quay số";
        document.title = title;

        setCookie(COOKIES.GENERATE_KEY, response?.data?.generateKey, 1);
        setWheelPrizesState(
          response?.data?.prizes,
          response?.data?.luckySpinGui
        );
      } else if (response.code === 423) {
        setIsOpenInputPassForm(true);
      } else if (response.code === 404) {
        setIsNotFound(true);
      } else {
        message.error(response.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const fetchClientsPlayerDialData = async () => {
      setLoading(true);
      try {

        const response = await getDialClientPlayers({refId: lastSegment, playerType: 1});

        if (response.code === 200) {
          setClientPlayerDialData(response?.data);

        } else {
          message.error(response.message);
        }
      } catch (error) {
        console.log("Error", error)
      } finally {
        setLoading(false);
      }
    }
  ;

  if (loading) {
    return (
      <div className="w-[100vw] h-[100vh] flex items-center justify-center">
        <img src={loadingIcon} alt="loading" className="w-20 h-20"/>
      </div>
    );
  }

  const reloadConfig = () => {
    setIsOpenInputPassForm(false);
    fetchConfigSpinData();
  };

  const getBackgroundPage = () => {
    if (eventType == EVENT_TYPES.DIAL && !configs?.isBackground) {
      return `#${configs?.backgroundColor}`;
    }

    return `url('${
      isMobile ? configs?.mobileImageUrl : configs?.desktopImageUrl
    }')`;
  };

  return (
    <>
      <InitSignalR/>
      {isOpenInputPassForm ? (
        <InputPassForm reload={reloadConfig} spinId={lastSegment}/>
      ) : (
        <div
          className="bg-cover h-[100vh] relative overflow-hidden"
          style={{
            background: `${getBackgroundPage()} 0% 0% / 100vw 100vh`,
          }}
        >
          <InformationSpin configs={configs}/>
          <InformationUserForm/>
          <AwardModal/>

          {isNotFound && <NotFound/>}

          {eventType == EVENT_TYPES.DIAL && configs && (
            <LuckySlot configs={configs} clientPlayerDialData={clientPlayerDialData}/>
          )}

          {!eventType && configs && (
            <>
              {configs?.isShow && (
                <div className="hidden lg:block absolute left-[50px] top-1/2 -translate-y-1/2">
                  <ListPlayers configs={configs}/>
                </div>
              )}

              <div
                className="absolute -translate-y-1/2"
                style={{
                  right: isMobile ? "8%" : "50px",
                  top: isMobile ? "60%" : "53%",
                }}
              >
                <LuckyWheel spinId={lastSegment} isCustomGui={isCustomGui}/>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Home;
